import React, { useEffect, useState } from 'react'
import cn from 'classnames'
import Cookies from 'js-cookie'
import { XMarkIcon } from '@heroicons/react/24/outline'

import s from './index.module.css'

const Index = ({ metafields, locale, slug }) => {
  const { pageCommon = {} } = metafields
  const [newRelease, setNewRelease] = useState(false)

  useEffect(() => {
    if (slug) {
      setNewRelease(false)
    }
  }, [slug])

  useEffect(() => {
    const newReleaseCookie = Cookies.get('new-release-cookie')
    if (pageCommon?.newRelease && !newReleaseCookie) {
      setNewRelease(true)
    }
  }, [])

  const handleClose = () => {
    Cookies.set(
      'new-release-cookie',
      { locale, isNewRelease: false },
      {
        Domain: window.location.hostname,
        expires: 1,
      }
    )
    setNewRelease(!newRelease)
  }
  if (!pageCommon?.newReleaseUrl) return null
  return (
    <>
      <div className={cn(s.newRelease, { [s.releaseHeight]: newRelease })}>
        <div className={s.newReleaseWrap}>
          <a
            href={`/${locale}${pageCommon?.newReleaseUrl}`}
            dangerouslySetInnerHTML={{ __html: pageCommon?.newRelease }}
          />

          <button className={s.close} onClick={handleClose} aria-label="close">
            <XMarkIcon className="w-full" />
          </button>
        </div>
      </div>
    </>
  )
}

export default Index
